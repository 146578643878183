/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { isEqual } from 'lodash';

import Button from 'components/shared/Button';
import Input, { Radio, RadioGroup } from 'components/shared/Input';

import Select from 'components/shared/Select';

import { useCarrierDistricts } from 'api/v2/carriers';
import { useEditDriver } from 'api/v2/drivers';
import { useEquipment } from 'api/v2/equipment';

import { equipmentTypes } from 'components/globalInfo/equipment';

import targetValue from 'utils/targetValue';
import {
  districtOptions,
  formattedDriverDistricts,
  formattedDriverContainers,
  equipmentOptions,
} from './helpers';
import {
  useCarrierOptions,
  useContainerOptions,
  useEditDriverForm,
} from './hooks';

const defaultOnBoardingDistrict = Number(
  // eslint-disable-next-line no-underscore-dangle
  window._env_.DEFAULT_ONBOARDING_DISTRICT,
);
const EditDriver = ({
  showEditDriver,
  closeEditDriver,
  driverInf,
  activeOrder,
  driverDistricts,
}) => {
  const [selectedOption, setSelectedOption] = useState('Radio 1');
  const [editData, { setValue }] = useEditDriverForm({
    ...driverInf,
    districts: formattedDriverDistricts(driverDistricts),
    containers: formattedDriverContainers(driverInf.containers)
  });
  const { data: requiredEquipment } = useEquipment();
  const { mutate: editDriver, isPending: isEditDriverPending } = useEditDriver();
  const { data: districts, isLoading: isCarrierDistrictsLoading } = useCarrierDistricts({ carrierId: editData.carrierId });
  const {
    name,
    phone,
    email,
    carrierId: carrier,
    selectedDistricts,
    containers,
    truck,
    trailer,
    longitude,
    latitude,
    status,
    equipment,
    driverId,
    driverContainers
  } = editData;
  const carrierOptions = useCarrierOptions();

  const districtOptionsList = useMemo(() => {
    if (isCarrierDistrictsLoading) {
      return [];
    }

    const options = districtOptions(districts, selectedDistricts ?? []);

    if (!options.find(i => i.value === defaultOnBoardingDistrict) &&
      !selectedDistricts.find(i => i.id === defaultOnBoardingDistrict)) {
      options.push({
        value: defaultOnBoardingDistrict,
        label: `${defaultOnBoardingDistrict} | On-boarding`,
      });
    }

    return options;
  }, [districts, isCarrierDistrictsLoading, selectedDistricts]);

  const districtIds = selectedDistricts?.length
    ? selectedDistricts.map(d => d.value)
    : [];
  const containerOptions = useContainerOptions(driverId, districtIds);

  const handleSubmit = e => {
    e.preventDefault();
    const submitData = {
      ...editData,
      vehicleNumber: truck,
      trailerNumber: trailer,
      contactNo: phone,
      districtIds: selectedDistricts.map(district => district.value),
      containers: containers.map(c => c.value),
      district: selectedDistricts[0].value,
      lat: latitude,
      long: longitude
    }

    submitData.includesDistricts = !isEqual(submitData.districtIds, driverDistricts.map(district => district.id));

    editDriver(
      submitData,
      {
        onSuccess: closeEditDriver,
      }
    );
  }

  const saveDisabled = useMemo(() => (
    !editData.name.trim().length ||
    !editData.phone.trim().length ||
    !editData.carrierId ||
    !editData.selectedDistricts.length ||
    !editData.truck.trim().length ||
    !editData.trailer.trim().length ||
    !editData.longitude ||
    !editData.latitude ||
    !editData.equipment ||
    !isValidPhoneNumber(editData.phone) ||
    isEditDriverPending
  ), [editData, isEditDriverPending]);

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${10}%` }}
        className="modal-container"
        show={showEditDriver}
        onHide={closeEditDriver}>
        <form
          onSubmit={handleSubmit}
          className="m-t"
          role="form"
          encType="multipart/form-data">
          <Modal.Header>
            <h3>Edit Driver</h3>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(targetValue(e), 'name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="driver-details_edit_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(targetValue(e), 'email')}
                  value={email}
                  label="Email"
                  type="email"
                  testSelector="driver-details_edit_email_input"
                  required
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="tel"
                  onChange={e => setValue(targetValue(e), 'phone')}
                  value={phone}
                  label="Country Code and Phone  Number"
                  required
                  testSelector="driver-details_edit_phone_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Carrier"
                  onChange={item => {
                    setValue(item.value, 'carrierId');
                    setValue([], 'selectedDistricts');
                    setValue('', 'truck');
                  }}
                  value={carrierOptions.find(item => item.value === carrier)}
                  options={carrierOptions}
                  required
                  isDisabled={!!status}
                  testSelector="driver-details_edit_carrier_select"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(targetValue(e), 'truck')}
                  value={truck}
                  label="Truck number"
                  required
                  testSelector="driver-details_edit_truck_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(targetValue(e), 'trailer')}
                  value={trailer}
                  label="Trailer number"
                  required
                  testSelector="driver-details_edit_trailer_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => setValue(targetValue(e), 'latitude')}
                  value={latitude}
                  label="Latitude"
                  required
                  testSelector="driver-details_edit_lat_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => setValue(targetValue(e), 'longitude')}
                  value={longitude}
                  label="Longitude"
                  required
                  testSelector="driver-details_edit_long_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  placeholder="Districts"
                  closeMenuOnSelect={false}
                  onChange={item => {
                    setValue(item, 'selectedDistricts');
                    // If removing district, clear the containers and make user reselect container(s)
                    // because containers depend on districts on driver
                    if (item.length < selectedDistricts.length) {
                      setValue([], 'containers');
                    }
                  }}
                  value={selectedDistricts}
                  options={districtOptionsList}
                  required
                  testSelector="driver-details_edit_districts_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Equipment"
                  onChange={item => {
                    setValue(item.value, 'equipment');
                    if (!item.value !== 3) {
                      setValue([], 'containers');
                    }
                  }}
                  value={equipmentOptions(requiredEquipment).find(
                    item => item.value === equipment,
                  )}
                  options={equipmentOptions(requiredEquipment)}
                  required
                  testSelector="driver-details_edit-info_equipment_select"
                />
              </div>
            </Row>
            {equipment === equipmentTypes.Containerized &&
              (driverInf?.status ? (
                activeOrder?.require_tracked_containers ? (
                  <Select
                    isMulti
                    placeholder="Containers Tracked"
                    closeMenuOnSelect={false}
                    onChange={item => setValue(item, 'containers')}
                    value={containers}
                    options={containerOptions}
                    testSelector="driver-details_edit_containers_select"
                  />
                ) : (
                  <Input
                    onChange={e => {
                      const value = targetValue(e);
                      if (/^[a-zA-Z0-9-_]*$/.test(value)) {
                        setValue(value, 'driverContainers');
                      }
                    }}
                    value={driverContainers}
                    label="Containers UnTracked"
                    testSelector="driver-details_edit_truck_containers"
                  />
                )
              ) : (
                <>
                  <Row>
                    <div className="form-group has-feedback col-md-12">
                      <RadioGroup
                        name="radioGroup"
                        onChange={state =>
                          setSelectedOption(state.target.value)
                        }>
                        <Radio
                          label="UnTracked Container"
                          value="Radio 1"
                          isChecked={selectedOption === 'Radio 1'}
                        />
                        <Radio
                          label="Tracked Container"
                          value="Radio 2"
                          isChecked={selectedOption === 'Radio 2'}
                        />
                      </RadioGroup>
                    </div>
                  </Row>
                  {selectedOption === 'Radio 2' ? (
                    <Row>
                      <div className="form-group has-feedback col-md-12">
                        <Select
                          isMulti
                          placeholder="Containers Tracked"
                          closeMenuOnSelect={false}
                          onChange={item => setValue(item, 'containers')}
                          value={containers}
                          options={containerOptions}
                          testSelector="driver-details_edit_containers_select"
                        />
                      </div>
                    </Row>
                  ) : (
                    <Row>
                      <div className="form-group has-feedback col-md-12">
                        <Input
                          onChange={e => {
                            const value = targetValue(e);
                            if (/^[a-zA-Z0-9-_]*$/.test(value)) {
                              setValue(value, 'driverContainers');
                            }
                          }}
                          value={driverContainers}
                          label="Containers UnTracked"
                          testSelector="driver-details_edit_truck_containers"
                        />
                      </div>
                    </Row>
                  )}
                </>
              ))}
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeEditDriver}
              colour="white"
              testSelector="driver-details_edit-driver_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              disabled={saveDisabled}
              testSelector="driver-details_edit-driver_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

export default EditDriver;
